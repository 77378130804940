.lightbox-on {
  overflow: hidden;
}

.lightbox {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  @include fadeOut;
}

.lightbox_show {
  @include fadeIn;
}

.lightbox__close {
  position: absolute;
  top: -37px;
  right: 0px;
  font-size: 16px;
  line-height: 36px;
  padding: 0;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  color: #FFF;
  background-image: url(#{$imgDir}close-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  border: none;
  opacity: 1;
  z-index: 1;
  @include transition;
  &:hover {
    color: $color-1;
  }
}

.lightbox__overlay {
  width: 100%;
  min-height: 100%;
  padding: 35px 15px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: rgba(0,0,0,0.8);
}

.lightbox__content {
  position: relative;
  max-width: 460px;
  width: 100%;
  background-color: #000;
  padding: 30px 15px;
  text-align: center;
  .heading {
    color: $color-1;
  }
  @include media-breakpoint-up(sm) {
    padding: 60px;
  }
}

/*---------*/
/* CONTACT */
/*---------*/
#contact-lightbox .form {
  margin-top: 25px;
  @include media-breakpoint-up(sm) {
    margin-top: 50px;
  }
}

/*-------*/
/* VIDEO */
/*-------*/
[data-video-lightbox] .lightbox__content {
  // overflow: hidden;
  padding: 0;
  max-width: 960px;
  iframe {
    vertical-align: top;
  }
}

/*-----*/
/* TYC */
/*-----*/
#tyc-lightbox {
  .lightbox__content {
    max-width: 960px;
    .heading-3 {
      color: $color-1;
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 50px;
      }
    }
    .heading-4 {
      color: $color-1;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: 400;
    }
    p {
      text-align: left;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 22px;
      a {
        color: $color-1;
      }
    }
  }
}
