.social {
  @extend .clearfix;
}

.sc-cta-base {
    display: block;
    float: left;
    border: none;
    background: none;
    width: 1.2em;
    height: 1.2em;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    cursor: pointer;
    @include transition();
    &:not(:first-of-type) {
      margin-left: 0.8em;
    }

    &:hover, &:focus {
      color: $color-1;
    }
}

.sc-cta {
  @extend .sc-cta-base;
}

.sc-cta-circle {
  @extend .sc-cta-base;
  width: 1.75em;
  height: 1.75em;
  line-height: 1.75em;
  border-radius: 50%;
  i {
    line-height: inherit;
  }
}

// BORDER CIRCLE
.sc-cta-border {
  @extend .sc-cta-circle;
  line-height: calc(1.75em - 2px);
  border-width: 1px;
  border-style: solid;
  background-color: rgba(#626366, .3);
}

.sc-cta-border-main {
  @extend .sc-cta-border;
  border-color: $color-1;
  color: #FFF;
  &:hover, &:focus {
    border-color: #FFF;
    color: #FFF;
  }
}
