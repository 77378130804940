.modal-content{
  padding: 20px;
  border-top: 6px solid $color-1;
}

.modal-header{
    border-bottom: none;
    padding-bottom: 0;

}

.modal-title{
  color:#444;
  font-size: 34px;
}

.modal-body{
  p{
    margin-bottom: 20px;
    span{
      color:$color-1;
    }
  }
}

.modal-footer{
  border-top: none;
}
