.step-form{
  padding: 0 20px 20px;
  box-sizing: border-box;
  @include media-breakpoint-up(sm) {
   padding: 0 50px 20px;
  }

  .step-form-header{
    padding: 10px 20px;
    margin: 0 -20px 20px;
    border-bottom: 1px solid #ddd;
    @include media-breakpoint-up(sm) {

     margin: 0 -50px 20px;
    }


    h2{
      font-size: 1rem;
      font-weight: normal;
      color:$color-1;

      i{
        margin-right: 16px;
      }

      @include media-breakpoint-up(md) {
       font-size: 16px;
      }
    }
  }


    label{
      font-size: 14px;
      font-weight: 500;
    }

}



.edit-selected{
  margin-right: 15px;
  margin-top: -5px;
  cursor: pointer;
}

.form-content-box{
  border: 1px solid $border-color;
}

.file-upload-status{
  text-align: center;
  &.align-left{
    text-align: left;
  }

  .file-upload-done{
    color: $color-4;
  }

  .file-upload-active{
    color:$color-1;
  }

  .file-upload-error{
    color:#E53935;
  }

  .file-delete{
    color: #999;
    &:hover{
      color:$color-1;
    }
  }

}

.form-error-custom-label{
  color:#EF5350;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;


  p{
    font-size: 14px;
    margin: 10px 0 20px;
  }
  i{
    font-size: 16px;
    color:#E53935;
    margin-right: 10px;
  }
}

.custom-check{
  position: relative;
  input{
    position: absolute;
    top: 50%;
    left: 40px;
    margin-top: -5px;
  }
}

.form-subtitle {
  color:#444;
  margin-bottom: 5px;
  &.has-icon{
      padding-left: 40px;
  }
}

.form-subtext{
  line-height: 18px;
  margin-bottom: 20px;
  &.has-icon{
      padding-left: 40px;
  }
}

.form-icon{
  float: left;
  color: $color-2;
}

.jp-card-container{
  margin:20px 0 !important;
}

.quote-header{
    background-color: $color-1;
    padding: 10px 40px;
    margin: 0 -20px;
    color: #fff;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    p{
      color:$color-2;
      display: inline-block;
    }
    span{
      float: right;
      font-weight: bold;
    }
}

.quote-content-block {
  padding: 20px ;
  border-bottom:1px dashed #ddd;

  &:last-child{
    border-bottom: none;
  }
  p{
      font-size: 16px;
      color: #444444;
      letter-spacing: 0;
  }

  .quote-price{
    margin-top: 10px;
    font-size: 24px;
    color: #444444;
    letter-spacing: 0;
  }

}

.quote-title{
  font-weight: bold;
  font-size: 16px;
  color: #999999;
  letter-spacing: 0;
  margin-bottom: 14px;
  i{
    float: right;
    color:$color-4;
  }
  a{
    margin-left:10px ;
    font-size: 12px;
    color:#ccc;
    &:hover{
      color:$color-1;
    }
  }
}

.quote-label{
  font-size: 12px;
  color: #CCCCCC;
  letter-spacing: 0;
}

.quote-data{
  font-size: 16px;
  font-weight: 500;
  color: #444444;
  letter-spacing: 0;
}
