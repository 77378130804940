.next-step{
  margin-top: 20px;
  margin-bottom: 60px;
}
.current-step-indicator{
  margin: 20px 30px;
  span{
    font-size: 12px;
    color: #999999;
  }
  ul{
    display: block;
    li{
      display: inline-block;
      color:$color-2;
      i{
        font-size: 12px;
        margin-right: 6px;
      }
    }
  }
  .form-check-label{
    font-size: 12px;
  }
}

.success-message{
  padding: 20px 0;
  margin-right: 30px;
  h3{
    font-size: 16px;
    color: #444;
  }
  .lead{
    font-size: 14px;
  }
}
.next-step-cta{

  i{
    float: right;
    margin-top: 3px;
  }
  @include media-breakpoint-up(md) {
    margin-right: 30px;
  }
}

.step-success {
  i{
    color:$color-4;
    margin-right: 10px;
  }
}
