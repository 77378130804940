a.tooltips {
  position: relative;
}
a.tooltips span {
  position: absolute;
  width:80px;
  color: #FFF;
  background: $color-1;
  height: 30px;
  margin-bottom: 20px;
  line-height: 30px;
  text-align: center;
  visibility: hidden;
  border-radius: 6px;
  font-size: 14px;

  bottom: 40px;
  left: 50%;
  margin-left: -40px;
  z-index: 999;
  opacity: 0;
  @include transition(.25s);
}
a.tooltips span:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0; height: 0;
  border-top: 8px solid $color-1;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
a:hover.tooltips span {
  visibility: visible;
  opacity: 1;
  bottom: 50px;
}
