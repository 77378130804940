$success: $brand-success;
$error: #FF7B8B;
$text-color: #000;

.form {
}


.form-title {
	font-size: 16px;
	font-weight: 400;
	color: #999;
	text-align: left;
	margin: 0;
	line-height: 30px;
}

.form-divider {
	margin: 0;
	border: none;
	border-top: 1px solid $border-color;
}

.form-text {
	font-size: 18px;
	line-height: 25px;
	font-weight: 400;
  color: #444;
	a {
		color: $color-1;
		&:hover {
			color: lighten($color-1, 10);
		}
	}
}

.form__group {
	display: block;
	margin: 0 0 20px;
	label.error {
		display: block;
		font-size: 0;
		height: 0;
		margin: 0;
	}
}

.form__label {
	display: block;
	padding: 0px;
	line-height: 20px;
	font-size: 16px;
	font-weight: 400;
	color: #FFF;
	text-align: left;
	margin-bottom: 10px;
}

.form__input {
	display: block;
	border: 1px solid $color-1;
	font-size: 16px;
	padding: 14px 12px;
	line-height: 20px;
	width: 100%;
	box-sizing: border-box;
	resize: vertical;
	background-color: rgba(#FFF, 0);
	background-size: 13px;
	background-position: top 13px right 10px;
	background-repeat: no-repeat;
	@include transition;
	@include placeholder() {
		color: $color-1;
		opacity: 0.8;
		@include transition;
	}
	&:-webkit-autofill {
		background-color: #FFF !important;
		-webkit-box-shadow: 0 0 0px 1000px white inset;
	}
	&:focus {
		background-color: rgba(#FFF, .1);
		@include placeholder() {
			opacity: 0.4;
		}
	}
	&.error {
		border-color: $error;
	  background-image: url(#{$imgDir}input-error.png);
	}
}

select.form__input {
	background-color: transparent;
	background-image: url(#{$imgDir}select-tip.svg);
	background-size: 13px;
	background-position: top 13px right 10px;
	background-repeat: no-repeat;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	&:focus {
		outline: none;
	}
}

.form-checkbox-input {
	display: none;
	&:checked + .form-checkbox-mask {
		color: $text-color;
	}
}

.form-checkbox-mask {
	display: inline-block;
	height: 16px;
	width: 16px;
	border: 1px solid $border-color;
	border-radius: 2px;
	vertical-align: top;
	color: transparent;
	line-height: 16px;
	font-size: 14px;
	text-align: center;
	@include transition(.1s);
}

.form-checkbox-value {
	font-size: 14px;
	line-height: 18px;
	vertical-align: top;
	margin-left: 10px;
}

.form-error-label {
	display: block;
	color: $error;
	font-size: 12px;
	line-height: 20px;
	margin: -25px 0 30px;
}

.form-link {
  display: inline-block;
	font-size: 16px;
	color: #444;
  line-height: 40px;
  font-weight: 600;
	@include transition(.25s);
	&:hover {
		color: $color-1;
	}
  @include media-breakpoint-down(sm) {
    display: block;
    text-align: center;
  }
}
