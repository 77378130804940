header{
  position:relative;
  width:100%;
  height: 50px;
  background-color: #fff;
  border-bottom:#eee 1px solid;
  top:0;
  left:0;
  right:0;

  a:hover{
    color:#666;
  }

  z-index: 10;

  @include media-breakpoint-up(md) {
    height: 90px;
  }


}

.nav-container{
  max-width: 1280px;
  margin: 0 auto;
}
nav{
  float: right;
  margin-right: 10px;
  line-height: 46px;

  ul{
    list-style: none;
    margin:0;
    padding:0;
    box-sizing: border-box;

    li{
      display: inline-block;
      margin: 9px 3px;

      a:hover{
      color:#0063be;
      border-color:#0063be;
      }
    }
  }
}

.social{
  color:#999;
  border: 2px solid #999;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;

  text-align: center;
  display: block;
  box-sizing: border-box;
  font-size: 12px;
  height:28px;
  width:28px;
  line-height: 24px;
}

.link-ypf {
  color:#999;
  font-size: 14px;
  text-decoration: none;
  margin-left: 3px;
}

.logo{
  display: inline-block;
  max-width: 140px;
  @include media-breakpoint-up(md) {
    max-width: 170px;
    padding:12px 0;
  }
    img{
      width:100%;
      margin-top: 4px;
      }
  }

  .contact-phone{
    line-height: 50px;
    i{
      margin-right: 10px;
    }
    @include media-breakpoint-up(md) {
      line-height: 90px;
    }
  }

  // MEDIA.social{
  //   line-height: 28px;
  //   height: 32px;
  //   width:32px;
  // }
  // nav{
  //   margin-right: 10px;
  //
  // }
