.steps-box{
  margin-top: 20px;
  overflow: hidden;
}
.step-item {
  text-align: center;
  padding: 5px 0;
  line-height: 30px;
  cursor: default;
  @extend .transitions;


  &.active{
    cursor: pointer;
    .step-item-icon{
      background-color: $color-1;

      i{
        color:#fff;
      }
      &:after{
        border-left-color: $color-1;
      }
    }
    .step-item-text{
      color: $color-1;
    }
  }

  &.completed{
    cursor: pointer;

    &:hover{
      .step-item-icon{
        background-color: lighten($color-2,10);

        &:after{
          border-left-color: lighten($color-2,10);
        }
      }
      .step-item-text{
        color:$color-1;
      }
    }

    .step-item-icon{
      background-color: $color-2;
      i{
        color:$color-3;
      }

      &:after{
        border-left-color: $color-2;
      }
    }
    .step-item-text{
      color: #444;
    }
  }
}


.step-item-icon{
  display: block;
  background-color: #fff;
  color:#ccc;
  position: relative;
  border-bottom: 1px solid #ddd;


  &:after{
    left: 100%;
  	top: 50%;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
    border:1px solid;
  	border-color: rgba(136, 183, 213, 0);
  	border-left-color: #fff;
  	border-width: 15px;
  	margin-top: -15px;
    z-index:12;
  }

  &:before{
    left: 100%;
  	top: 50%;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
    border:1px solid;
  	border-color: rgba(136, 183, 213, 0);
  	border-left-color: #ddd;
  	border-width: 16px;
  	margin-top: -16px;
    z-index:12;
  }
}

.step-item-text{
  display: block;
  color:#999;
  font-size: 14px;
  font-weight: bold;
}
