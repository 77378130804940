.sp5, .sp10, .sp15, .sp20, .sp30, .sp40, .sp50, .sp60, .sp70 { display: block; }

.sp5 { height: 5px; }
.sp10 { height: 10px; }
.sp15 { height: 15px; }
.sp20 { height: 20px; }
.sp30 { height: 30px; }
.sp40 { height: 40px; }
.sp50 { height: 50px; }
.sp60 { height: 60px; }
.sp70 { height: 70px; }

.divider {
  display: block;
	margin: 0 auto;
  padding: 0;
  height: 0;
  width: 100%;
	border: none;
	border-top: 1px solid $color-1;
  &.small {
    max-width: 200px;
  }
}

.divider-gradient {
  @extend .divider;
  height: 1px;
  border: 0;
  background-image: linear-gradient(to right, transparent 20%, $border-color 50%, transparent 80%);
}

.video-box {
  position: relative;
  iframe, video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &.ratio-1-1:before { padding-bottom: percentage(1/1); }
  &.ratio-4-3:before { padding-bottom: percentage(3/4); }
  &.ratio-3-2:before { padding-bottom: percentage(2/3); }
  &.ratio-5-3:before { padding-bottom: percentage(3/5); }
  &.ratio-16-9:before { padding-bottom: percentage(9/16); }
  &.ratio-3-1:before { padding-bottom: percentage(1/3); }
}
