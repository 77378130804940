.data-table {
  margin-top: 20px;
  .table{
    margin-bottom: 0;
    th{
      padding: 10px 20px;
    }
    td{
      padding: 10px 20px;
    }
  }
}

.title-row{
  th{
    font-size: 14px;
    font-weight: normal;
    color:$color-3;
    vertical-align: middle !important;
  }

}

.header-row {
  th{
    padding-left: 20px;
    text-align: right;
    font-size: 12px !important;
    font-weight: normal;
  }
  td{
    color:$color-1;
    font-size: 1.25rem;
    font-weight: bold;
  }
}

.header-sub-row {
  th{
    padding-left: 20px;
    text-align: right;
    font-size: 12px !important;
    font-weight: normal;
  }

  td{
    color:#444;
    font-size: 1rem;
    font-weight: bold;
  }
}

.content-row {
  background-color: #FEFDF5;

  th{
    text-align: right;
    font-size: 12px !important;
    font-weight: normal;
    padding: 10px !important;
    box-sizing: border-box;
  }
  td{
    font-size: 14px;
  }

  .fa-check{
    color:$color-4;
  }
}

.mobile-table-item{
  margin-top: 20px;
}

.mobile-table-header{
  padding: 20px;
}

.mobile-table-header-title{
  font-size: 16px;
  font-weight: 500;
  color: $color-2;
  margin-bottom: 20px;

}

.mobile-table-content{
  ul{
      background-color: #FEFDF5;
  }
  li{
    font-size: 12px;
    text-align: left;
    padding: 10px 20px;
    border-top: 1px dashed #ddd;
    i{
      margin-right: 10px;
      color:$color-4;
    }

  }
}

.mobile-table-cta{
  padding: 20px;
  border-top: 1px solid #ddd;
}

.quote-data.price{
  color:$color-1;
  font-size: 22px;
}

.quote-data.franquicia{
  color: #999;
  font-size: 22px;
}
