.parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //border: 1px solid $color-1;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
  }
  //z-index: 1;
  [data-parallax] {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    left: -30%;
    width: 160%;
    [data-parallax] {
      width: auto;
      min-height: 100%;
      min-width: 100%;
    }
  }

/*
  [data-skew] {
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }

  @include media-breakpoint-up(md) {
    width: 290px;
    height: 290px;
    float: right;
    margin-top:60px;
    margin-left: 80px;
  }

  @include media-breakpoint-up(lg) {
    width: 470px;
    height: 470px;
    float: right;
    margin: -25px 0;
    margin-left: 80px;
  }
  */
}
