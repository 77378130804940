.certificados{
  background-color: #fff;
  padding: 60px 0;
  border-top: 1px solid #ddd;
  img{
    display: inline-block;
    width:100%;
    margin-bottom: 20px;
  }

  .afip img{
    max-width: 50px;

  }

  .ssn img{
  }

  .godaddy {
    text-align: left;
    img{
      max-width: 200px;
    }
    @include media-breakpoint-up(lg) {
     text-align: right;
    }

  }

}
.footer-legales{
  font-size: 12px;
}

.links{
  padding: 10px;
  background-color: #eee;

  a{
    color:#444;
    font-size: 14px;
    margin-right: 20px;
    padding: 10px;
    display: block;
    &:hover{
      color:$color-1;
    }
    @include media-breakpoint-up(md) {
     display: inline-block;
    }
  }
}
