html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  // -webkit-font-smoothing: antialiased;
  // display: -webkit-flex;
  // display: -ms-flex;
  // display: flex;
  // -webkit-flex-direction: column;
  // flex-direction: column;
  // -webkit-align-items: stretch;
  // align-items: stretch;
  font-family: $font-1;
  overflow-x: hidden;
  background-color: #fafafa;
  color: #999;
  display: block;
}


// .navbar, .main-content, .footer {
//   -webkit-flex-shrink: 0;
//   flex-shrink: 0;
// }

.main-content {
  // -webkit-flex-grow: 1;
  // flex-grow: 1;
  overflow: hidden;
}

.main-block {
  width:100%;
  max-width: 1280px;
  margin:50px auto 0;
  background-color: #000;
  display: block;
  position: relative;
  // background-image: url(#{$imgDir}hamburguesa-bg-sm.jpg);
  // -webkit-background-size: cover;
  //   -moz-background-size: cover;
  //   -o-background-size: cover;
  //   background-size: cover;

}
// MEDIA
  // height: calc(100vh - 130px);
