#toggle-main-menu {
  float: right;
  width: 50px;
  height: 50px;
  border: none;
  color: #FFF;
  background-color: transparent;
  background-image: none;
  position: relative;
  cursor: pointer;
  @include transition(.25s);
  &:hover {
    color: $color-1;
  }
  @include media-breakpoint-up(md){
    margin-right: 10px;
  }
}

.main-menu {
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
  border: 1px solid $color-1;
  cursor: default;
  width: 220px;
  @include fadeOut;
  a {
    display: block;
    padding: 10px 15px;
    font-size: 13px;
    line-height: 20px;
    color: $color-1;
    background-color: #000;
    text-align: left;
    cursor: pointer;
    @include transition(.25s);
    &:hover {
      color: lighten($color-1, 10);
      background-color: #111;
    }
    &.active {
      color: #FFF;
    }
  }

  &:before {
    content: '\f00d';
    display: block;
    font-family: 'FontAwesome';
    line-height: 50px;
    text-align: center;
    position: absolute;
    background-color: #000;
    top: -51px;
    right: -1px;
    width: 50px;
    height: 51px;
    border: 1px solid $color-1;
    border-bottom: none;
    pointer-events: none;
  }
  &.active {
    @include fadeIn;
  }
}
