.heading {
  line-height: 1.4;
  font-weight: 300;
  color: inherit;
  strong, b {
    font-weight: 700;
  }
}

.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  @extend .heading;
}

.heading-1 { font-size: 34px; }
.heading-2 { font-size: 24px; }
.heading-3 { font-size: 22px; }
.heading-4 { font-size: 20px; }
.heading-5 { font-size: 18px; }
.heading-6 { font-size: 16px; }

@include media-breakpoint-up(md) {
  .heading-1 { font-size: 60px; }
  .heading-2 { font-size: 40px; }
  .heading-3 { font-size: 32px; }
  .heading-4 { font-size: 24px; }
  .heading-5 { font-size: 20px; }
  .heading-6 { font-size: 16px; }
}

.section-header {
  text-align: center;
  position: relative;
  padding: 20px 0;
  .heading {
    color: $color-1;
  }
}
