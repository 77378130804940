.step-description{
  padding: 40px 0 20px;

  h1{
    font-size: 1.5rem;
    color: #444;
    @include media-breakpoint-up(md) {
     font-size: 2.5rem;
    }
  }

  .lead{
    margin-top: 5px;
    font-size: 1rem;
    @include media-breakpoint-up(md) {
     font-size: 1.25rem;
    }
  }
}
