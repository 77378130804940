/*-------------*/
/* DIRECTORIES */
/*-------------*/
$imgDir: '../img/';

/*-------*/
/* FONTS */
/*-------*/
$font-1: 'Helvetica Neue', Arial, sans-serif;

/*--------*/
/* COLORS */
/*--------*/
$color-1: #0F6EA5; // Boston Blue
$color-2: #ABCDE1; // Boston Light Blue
$color-3: #82A8BE; // Washed Dark Blue
$color-4: #2FC878;
$border-color: #DDD;

$social-networks-colors: (
  twitter: 	  #2FC2EF,
  facebook: 	#3B5998,
  instagram: 	#E1306C,
  google:     #D14228,
  youtube: 	  #e62117,
  whatsapp:   #84CB33,
  linkedin:   #007bb6,
  vimeo:      #4bf
);
