@mixin fonts($fonts) {
  @each $fontName, $variants in $fonts {
    @each $varianProps in $variants {
      @font-face {
        font-family: $fontName;
        font-style: map-get($varianProps, font-style);
        font-weight: map-get($varianProps, font-weight);
        src: url(map-get($varianProps, src)) format(map-get($varianProps, format));
      }
    }
  }
}

$baseFontsSrc: '../font/';
$fonts: (
  'din': ((
      font-style: normal,
      font-weight: 400,
      src: '#{$baseFontsSrc}din-regular.ttf',
      format: 'ttf'
    ),
    (
      font-style: normal,
      font-weight: 500,
      src: '#{$baseFontsSrc}din-medium.ttf',
      format: 'ttf'
    ),
    (
      font-style: normal,
      font-weight: 600,
      src: '#{$baseFontsSrc}din-bold.ttf',
      format: 'ttf'
    ),
    (
      font-style: normal,
      font-weight: 700,
      src: '#{$baseFontsSrc}din-black.ttf',
      format: 'ttf'
    ))
    // 'din-condensed': ((
    //     font-style: normal,
    //     font-weight: 400,
    //     src: '#{$baseFontsSrc}dincond-regular.otf',
    //     format: 'otf'
    //   ),
    //   (
    //     font-style: normal,
    //     font-weight: 600,
    //     src: '#{$baseFontsSrc}dincond-bold.otf',
    //     format: 'otf'
    //   ))
);

// @include fonts($fonts);
