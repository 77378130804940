.downloads {
  margin-top: 25px;
  @include media-breakpoint-up(sm) {
    margin-top: 50px;
  }
}

.downloads-item {
  display: block;
  border: 1px solid $color-1;
  text-align: left;
  color: #FFF;
  padding: 14px 20px;
  line-height: 20px;
  &:hover, &:focus {
    border-color: #FFF;
    color: #FFF;
  }
  i {
    line-height: 20px;
    vertical-align: top;
    &.pull-left {
      margin-right: 10px;
    }
  }
  + .downloads-item {
    margin-top: 10px;
  }
}
