.circle-cta {
  position: relative;
  display: block;
  height: 230px;
  width: 230px;
  line-height: 226px;
  border-radius: 50%;
  font-size: 40px;
  padding-left: 8px;
  border: 2px solid $color-1;
  color: #FFF;
  text-align: center;
  text-shadow: 0 2px 24px $color-1, 0 1px 4px rgba(0,0,0,.5);
  cursor: pointer;
  @include coverImg;
}
