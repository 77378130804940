
.lead-form-block{
  margin-top: 20px;

  .step-form{
    @include media-breakpoint-up(md) {
      padding: 0 50px 50px;
    }
  }
  .content-box{
    border-top: 6px solid $color-1;
    h1{
      color:#444;
      font-size: 1.5rem;
      margin: 30px 0 20px ;
    }
  }
}

.lead-question{
  margin-bottom: 10px;
  font-weight: normal;
  color: $color-3;

}

.listado-beneficios{
  margin: 20px 0;
  li{
    font-size: 1.5rem;
    font-weight: lighter;
    line-height: 60px;
    color:#444;

    i{
      color:$color-1;
      margin-right: 20px;
      font-size: 1.5rem;
    }
  }

}

.hero-banner{
  background:$color-1;
  background-image: url(#{$imgDir}hero-header-bg-dark.jpg);
  background-size: cover;
  background-position: center;
  padding: 45px 0;
  color:#fff;
  text-shadow: 1px 1px 3px rgba(40, 40, 40, 1);

  @include media-breakpoint-up(md) {
    margin-top: -40px;
    padding: 90px 0;
  }

  h2{
    margin-bottom: 30px;
  }

  .lead{
    max-width: 660px;
  }
}

.elegirnos{
  background-color: #fff;
  padding:45px 0;
  text-align: center;
  h3{
    font-size: 2.5rem;
    color:$color-3;
  }
  .lead{
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .sub-title{
    margin-bottom: 45px;
    font-size: 1.25rem;
  }

  h4{
    margin: 20px 0;
    color:#444;
  }

}

.features{
  background-color: $color-1;
  color:#fff;
  padding: 30px 0;
  text-align: center;

  .feature-item{
    margin:30px;
    display: block;
    font-size: 1.25rem;
    font-weight:lighter;
    i{
      margin-right: 20px;
      font-size: 2rem;
      @include media-breakpoint-up(md) {
        margin-right:0;
        margin-bottom: 20px;
      }
    }
  }
}
