.background-video-player {
  position: absolute;
  top: 0;
  right: 50%;
  margin-right: -150vw;
  width: 300vw;
  height: 100%;
  text-align: center;
}

.background-video-player-width {
  display: inline-block;
  height: 100%;
  width: 100%;
  min-width: 100vw;
  vertical-align: top;
  position: relative;
  background-image: url(#{$imgDir}bg-alt.jpg);
  background-size: cover;
  background-position: center;
  svg {
    vertical-align: top;
    height: 100%;
  }
}

.background-video-player-box {
  position: absolute;
  top: 50%;
  margin-top: -28.125%;
  width: 100%;
  text-align: left;
  video {
    display: none;
  }
  @include media-breakpoint-up(md) {
    background-image: none;
    video {
      display: block;
    }
  }
}
