
  video#bgvid {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    position: fixed;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(polina.jpg) no-repeat;
    background-size: cover;
  }



  @media screen and (max-device-width: 640px) {
  html {
       background: url(content/video_backup.png) #f3f3f1 no-repeat center center fixed;
  }
  #bgvid {
      display: none;
  }
}
