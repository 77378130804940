@mixin transition($transition-time: .25s, $transition-property: all, $method: ease-in-out) {
    transition: $transition-property $transition-time $method;
}

@mixin transform($set) {
  -webkit-transform: $set;
  -ms-transform: $set;
  transform: $set;
}

@mixin selection($bg, $color: #000){
    &::selection {
        background: $bg;
        color: $color;
    }
    &::-moz-selection {
        background: $bg;
        color: $color;
    }
}

@mixin placeholder(){
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    } // Firefox 19+
    &:-ms-input-placeholder {
        @content;
    } // Internet Explorer 10+
    &::-webkit-input-placeholder {
        @content;
    } // Safari and Chrome
}


@mixin keyframes($name) {
  @-webkit-keyframes $name {
      @content;
  }
  @-moz-keyframes $name {
      @content;
  }
  @-o-keyframes $name {
      @content;
  }
  @keyframes $name {
      @content;
  }
}

@mixin fadeIn($transition-time: 0.5s, $extraAnimation: ''){
	visibility: visible;
	opacity: 1;
	transition: opacity $transition-time ease-in-out, visibility 0s linear 0s #{$extraAnimation};
}

@mixin fadeOut($transition-time: 0.5s, $extraAnimation: ''){
	visibility: hidden;
	opacity: 0;
	transition: opacity $transition-time ease-in-out, visibility 0s linear $transition-time #{$extraAnimation};
}

@mixin coverImg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/*--------------------*/
/* EXTENDABLE CLASSES */
/*--------------------*/

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.verticalAlign {
  display: inline-block;
  vertical-align: middle;

   &+span {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.transitions {
  transition: all 0.3s;
  transition-timing-function: ease-in-out;
}
