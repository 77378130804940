.button-base {}

.btn{
	cursor:pointer;
}

.btn-primary{
	background-color: $color-1;
	&:hover{
		background-color: lighten($color-1,10);
	}
}

.btn-disabled {
	background-color: #eee;
	color: #999;
	cursor: default;
	border:1px solid #ddd;
	&:hover{
		background-color: #eee;
		color: #999;
	}
}

.btn-outline-primary{
	border-color: $color-1;
	color: $color-1;
	&:hover{
		background-color: lighten($color-1,10);
	}
}

.btn-outline-secondary{
	border-color: #DDD;
	color: #999;
	&:hover{
		background-color: #fff;
		border-color: $color-1;
		color: $color-1;
	}
	&.active{
		background-color: #fff !important;
		border-color: $color-1 !important;
		color: $color-1 !important;
		box-shadow: none !important;
	}
}


.btn-file {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid $color-1;
    color: $color-1;
    cursor: pointer;
    width: 100%;
		&:hover{
			color: lighten($color-1,10);
			border-color: lighten($color-1,10);
		}
}

.btn-file-photos {
    background-color: #fff;
    border: 1px dashed $color-3;
    color: $color-3;
    text-align: center;

    span{
      font-weight: normal;
      font-size: 12px;
      margin-bottom: 10px;
      line-height: 20px;
    }

    i {
      line-height: 40px;
      font-size: 1.25rem;
    }

    &:hover{
        color:$color-1;
    }

    &.uploaded{
      // background-color: $color-2;
      border: 1px solid $color-1;
			.vehicle-reference{
				opacity: 1;
			}

    }
		.vehicle-reference{
		    width:100%;
		    display: block;
				opacity: 0.4;
		}
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}


@mixin createBtn(
		$border-width: 0px,
		$font-offset: 0px,
		$color: #FFF,
		$background-color: transparent,
		$border-color: transparent,
		$transition: 0.25s,
		$border-radius: 0,
		$font-weight: 400,
		$rouded: false,
		$hover-background-color: false,
		$hover-border-color: false,
		$hover-color: false) {
	@extend .button-base !optional;
	display: inline-block;
	box-sizing: border-box;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	border: $border-width solid $border-color;
	color: $color;
	background-color: $background-color;
	font-weight: $font-weight;
	cursor: pointer;
	@if $rouded {
  	border-radius: 20px;
	} @else {
		border-radius: $border-radius;
	}

	$padding-top: 8px - $border-width + $font-offset;
	$padding-bottom: 8px - $border-width - $font-offset;
	padding: $padding-top 20px $padding-bottom;
	@include transition($transition);

  &.small {
    font-size: 14px;
    line-height: 20px;
		$padding-top-small: $padding-top - 3px;
		$padding-bottom-small: $padding-bottom - 3px;
		padding: $padding-top-small 15px $padding-bottom-small;

		@if $rouded {
    	border-radius: 15px;
  	}
  }

  &.large {
    font-size: 18px;
    line-height: 26px;
		$padding-top-large: $padding-top + 4px;
		$padding-bottom-large: $padding-bottom + 4px;
		padding: $padding-top-large 25px $padding-bottom-large;

		@if $rouded {
    	border-radius: 25px;
  	}
  }

  &.full {
    width: 100%;
  }

	&:hover, &:focus {
		@if $hover-background-color { background-color: $hover-background-color; }
		@if $hover-border-color { border-color: $hover-border-color; }
		@if $hover-color { color: $hover-color; }
	}
}

/* BUTTONS */
.button-play {
	$button-play: (
		border-radius: 50%,
		border-width: 2px,
		border-color: $color-1,
		color: #FFF,
		background-color: rgba(0,0,0,.2),
		hover-border-color: #FFF,
		hover-color: #FFF
	);
	@include createBtn($button-play...);

	width: 70px;
	height: 70px;
	line-height: 60px;
	font-size: 24px;
	padding: 4px 0 0 3px;
}

.button-border {
	$button-border: (
		font-offset: 1,
		border-width: 2px,
		border-color: #fff,
		color: #FFF,
		hover-border-color: #FFF,
		hover-color: #FFF
	);
	@include createBtn($button-border...);
}

.button-border-colored {
	$button-border-colored: (
		font-offset: 1,
		border-width: 2px,
		border-color: $color-1,
		color: #FFF,
		background-color: rgba($color-1,.2),
		hover-background-color: $color-1,
		hover-color: #FFF
	);
	@include createBtn($button-border-colored...);
}

.button-border-black {
	$button-border-black: (
		font-offset: 1,
		border-width: 1px,
		border-color: $color-1,
		color: #FFF,
		background-color: rgba(#000,.8),
		hover-color: #FFF,
		hover-border-color: #FFF
	);
	@include createBtn($button-border-black...);
}

.button-infinia-black {
	$button-infinia-black: (
		font-offset: 1,
		border-width: 1px,
		border-color: #00a1e3,
		color: #FFF,
		background-color: rgba(#000,.8),
		hover-color: #FFF,
		hover-border-color: #FFF
	);
	@include createBtn($button-infinia-black...);
}
